import type { TableProviderProps } from '@elseu/sdu-titan';
import { t } from '@lingui/macro';

const tablePagination = () => {
  return {
    pageSizeOptions: [10, 25, 50],
    labelPageNext: t`Volgende pagina`,
    labelPagePrevious: t`Vorige pagina`,
    labelPageSizeOption: ({ pageSize }) => t`${pageSize} rijen`,
    labelPageCurrent: ({ startRows, endRows, totalCount }) =>
      t`${startRows}-${endRows} van de ${totalCount} rijen`,
    labelColumnSort: ({ columnHeader, sortDirection }) => {
      const sortDirectionLabel = sortDirection === 'asc' ? t`oplopend` : t`aflopend`;
      if (!columnHeader) return t`Sorteer ${sortDirectionLabel}`;
      return t`Sorteer "${columnHeader}" ${sortDirectionLabel}`;
    },
    hasPagination: true,
  } as Partial<TableProviderProps<any>>;
};

export default tablePagination;
