import type { Money, ShareSeries, ShareType, ShareTypeVersion } from '@elseu/sdu-evidend-graphql';
import type { FilterFnOption, SortingFn } from '@tanstack/table-core';
import { formatNumber, formatShareSeries, formatShareTypeName } from 'helpers/formatShares';
import { formatMoney, moneyToNumber } from 'helpers/money';

const isEmpty = (value: any) => value === null || value === undefined || Number.isNaN(value);
const getRowValues = (aRow: any, bRow: any, id: any) => [aRow.getValue(id), bRow.getValue(id)];
const withEmptyCheck = (cb: (a: any, b: any) => number) => (aRow: any, bRow: any, id: any) => {
  const [a, b] = getRowValues(aRow, bRow, id);
  if (isEmpty(a) && isEmpty(b)) return 0;
  if (isEmpty(a)) return -1;
  if (isEmpty(b)) return 1;
  return cb(a, b);
};

export const shareTypeSortingFn = (
  a: ShareType | ShareTypeVersion,
  b: ShareType | ShareTypeVersion,
) => {
  const customName = a.name.localeCompare(b.name);
  if (customName !== 0) return customName;

  if (a.classifier && b.classifier) {
    const classifier = a.classifier.localeCompare(b.classifier);
    if (classifier !== 0) return classifier;
  }

  const aNV = moneyToNumber(a.nominalValue as Money);
  const bNV = moneyToNumber(b.nominalValue as Money);
  if (aNV > bNV) return 1;
  if (bNV > aNV) return -1;
  return 0;
};
export const sortTypes = (): Record<string, SortingFn<any>> => ({
  date: withEmptyCheck((a: string, b: string) => {
    const aDate = new Date(a);
    const bDate = new Date(b);

    if (aDate > bDate) return 1;
    if (bDate > aDate) return -1;
    return 0;
  }),
  money: withEmptyCheck((a: Money, b: Money) => {
    const aNumber = moneyToNumber(a);
    const bNumber = moneyToNumber(b);

    if (aNumber > bNumber) return 1;
    if (bNumber > aNumber) return -1;
    return 0;
  }),
  shareSeries: withEmptyCheck((a: ShareSeries, b: ShareSeries) => {
    if (a.from > b.from) return 1;
    if (b.from > a.from) return -1;

    if (a.to > b.to) return 1;
    if (b.to > a.to) return -1;

    return 0;
  }),
  boolean: withEmptyCheck((a: boolean, b: boolean) => {
    if (a === b) return 0;
    if (a) return 1;
    return -1;
  }),
  arrayLength: withEmptyCheck((a: any[], b: any[]) => {
    if (a.length > b.length) return 1;
    if (b.length > a.length) return -1;
    return 0;
  }),
});

const stringIncludes = (haystack: string, needle: string) =>
  String(haystack).toLowerCase().includes(String(needle).toLowerCase());

const normalizeFormattedNumber = (value: string) => value.replace(/[., ]/g, '');

const globalFilterMapping = {
  shareType: (value: ShareType, filter: string) =>
    stringIncludes(
      normalizeFormattedNumber(formatShareTypeName(value)),
      normalizeFormattedNumber(filter),
    ),
  money: (value: Money, filter: string) =>
    stringIncludes(normalizeFormattedNumber(formatMoney(value)), normalizeFormattedNumber(filter)),
  number: (value: number, filter: string) =>
    stringIncludes(normalizeFormattedNumber(formatNumber(value)), normalizeFormattedNumber(filter)),
  shareSeries: (shareSeries: ShareSeries, filter: string) =>
    stringIncludes(
      normalizeFormattedNumber(formatShareSeries(shareSeries)),
      normalizeFormattedNumber(filter),
    ),
};

// row: Row<TData>,
//     columnId: string,
//     filterValue: any,
//     addMeta: (meta: any) => void

export const createGlobalFilter = (
  mapping: Record<string, keyof typeof globalFilterMapping> = {},
) => {
  const filterFn: FilterFnOption<any> = (row, columnId, filterValue) => {
    const rowValue = row.getValue<any>(columnId);
    if (columnId in mapping) {
      return globalFilterMapping[mapping[columnId]](rowValue, filterValue);
    }
    return stringIncludes(rowValue, filterValue);
  };
  return filterFn;
};
