import { Button, Flex, Link, PlusIcon, Text } from '@elseu/sdu-titan';
import { Trans } from '@lingui/macro';
import { useWizardContext, WizardFooter, WizardHeader } from 'components/Wizard';
import { useSeriesDataMutationForm } from 'forms/mutation/useSeriesDataMutationForm';
import React from 'react';
import { FormProvider } from 'react-hook-form';
import { IssuancePartyCard } from 'wizardsteps/Issuance/IssuancePartyCard';

export const IssuanceShares = () => {
  const { visitNextStep, mutation, previousStep, refetchMutation } = useWizardContext();
  const [
    form,
    {
      expandedCardIndex,
      addCard,
      fields,
      hasError,
      toggleCard,
      removeCard,
      isSubmitDisabled,
      isLoading,
      handleMutationSubmit,
      labels,
    },
  ] = useSeriesDataMutationForm({
    mutation,
    onSuccess: visitNextStep,
  });
  return (
    <FormProvider {...form}>
      <WizardHeader previousStep={previousStep} title={labels.header}>
        {labels.introduction}
      </WizardHeader>

      <Flex justifyContent="space-between">
        <Text color="grey80" spaceAfter={6} type="labelBold">
          <Trans>Uitgifte(n)</Trans>
        </Text>
        <Link prefixAdornment={<PlusIcon />} onClick={addCard}>
          <Trans>Toevoegen</Trans>
        </Link>
      </Flex>

      {fields.map(({ key }, cardIndex: number) => (
        <IssuancePartyCard
          key={key}
          hasError={hasError(cardIndex)}
          isExpanded={expandedCardIndex === cardIndex}
          labels={labels}
          mutation={mutation}
          nestedField={`cards[${cardIndex}].`}
          onChangeExpanded={() => toggleCard(cardIndex)}
          onDelete={() => removeCard(cardIndex)}
          onPartyCreate={() => refetchMutation()}
          onShareTypeCreate={() => refetchMutation()}
        />
      ))}

      <WizardFooter isLoading={isLoading} label={labels.footer}>
        <Button isDisabled={isSubmitDisabled} variant="primary" onClick={handleMutationSubmit}>
          <Trans>Volgende</Trans>
        </Button>
      </WizardFooter>
    </FormProvider>
  );
};
