import type { RegisterMutation, ShareType } from '@elseu/sdu-evidend-graphql';
import { CardCollapsible, Link, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import type { OnPartyCreateFn } from 'forms/mutation/PartyCreateForm';
import type { ShareSeriesInputRowLabels } from 'forms/mutation/ShareSeriesInputRow';
import { getPartyName } from 'helpers/getFromParty';
import React, { useMemo } from 'react';
import { useFormContext, useWatch } from 'react-hook-form';
import { IssuancePartyCardContent } from 'wizardsteps/Issuance/IssuancePartyCardContent';

type IssuancePartyCardLabels = {
  sharesHeader?: string;
  partyRefCreate?: string;
} & ShareSeriesInputRowLabels;

export interface IssuancePartyCardProps {
  mutation: RegisterMutation;
  nestedField: string;
  onDelete: () => void;
  hasError?: boolean;
  onPartyCreate: OnPartyCreateFn;
  onShareTypeCreate: (shareType: ShareType) => void;
  isExpanded: boolean;
  onChangeExpanded: (expanded: boolean) => void;
  labels: IssuancePartyCardLabels;
}

export const IssuancePartyCard = ({
  hasError,
  isExpanded,
  nestedField,
  onDelete,
  onChangeExpanded,
  mutation,
  ...rest
}: IssuancePartyCardProps) => {
  const { control } = useFormContext();

  const parties = useMemo(() => mutation.register.shareholders, [mutation.register.shareholders]);
  const toId = useWatch({
    control,
    name: `${nestedField}to.id`,
    disabled: !isExpanded,
  });

  const title = useMemo(() => {
    const toParty = parties.find((party) => party.id === toId);
    if (toId && toParty) {
      return t`Nieuwe uitgifte aan ${getPartyName(toParty)}`;
    }
    return t`Nieuwe uitgifte`;
  }, [toId, parties]);

  const description = useMemo(() => {
    if (hasError)
      return (
        <Text color="danger50" type="label">
          <Trans>Bevat een waarde die niet wordt geaccepteerd.</Trans>
        </Text>
      );
  }, [hasError]);

  return (
    <CardCollapsible
      description={description as unknown as string}
      expandComponent={
        <Link>
          <Trans>Wijzig</Trans>
        </Link>
      }
      footer={
        <Link onClick={onDelete}>
          <Trans>Verwijderen</Trans>
        </Link>
      }
      isExpanded={isExpanded}
      spaceAfter={4}
      title={title}
      onChangeExpanded={onChangeExpanded}
    >
      {isExpanded && (
        <IssuancePartyCardContent mutation={mutation} nestedField={nestedField} {...rest} />
      )}
    </CardCollapsible>
  );
};
