import { useWizardContext, WizardSummary } from 'components/Wizard';
import { LegalEntityPersonalInfo } from 'legalEntities/components/LegalEntityPersonalInfo';
import { IssuanceSummaryCards } from 'summaries/IssuanceSummaryCards/IssuanceSummaryCards';

export const IssuanceSummary = () => {
  const { mutation } = useWizardContext();
  const legalEntityId = mutation.register.legalEntity.id;
  return (
    <WizardSummary>
      <LegalEntityPersonalInfo hasHeaderButton={false} legalEntityId={legalEntityId} />

      <IssuanceSummaryCards mutation={mutation} />
    </WizardSummary>
  );
};
