import { useQuery } from '@apollo/client';
import type { Register, ShareType } from '@elseu/sdu-evidend-graphql';
import type { TableColumns } from '@elseu/sdu-titan';
import {
  ActionMenu,
  Button,
  Card,
  PlusIcon,
  Table,
  TableCommandBar,
  TableProvider,
  Tag,
} from '@elseu/sdu-titan';
import type { StatusColors } from '@elseu/sdu-titan/dist/types/types/status-colors';
import { t, Trans } from '@lingui/macro';
import { useLingui } from '@lingui/react';
import { ShareTypeSidebarForm } from 'components/ShareTypeSidebarForm/ShareTypeSidebarForm';
import { useWizardContext, WizardFooter, WizardHeader } from 'components/Wizard';
import tablePagination from 'forms/helpers/tablePagination';
import { ShareTypeFormMode } from 'forms/sharetype/ShareTypeEditForm';
import { REGISTER_QUERY } from 'graphql/queries/register';
import type { ActionOption } from 'helpers/actionMenu';
import { mapOptionsToActionMenuItems } from 'helpers/actionMenu';
import { formatShareTypeName } from 'helpers/formatShares';
import type { ShareTypeVersionWithShareType } from 'helpers/shareTypes';
import { shareTypeSortingFn } from 'helpers/table';
import { getLabels } from 'hooks/useLabels';
import { last } from 'lodash';
import { useMemo, useState } from 'react';
import type { RegisterQuery, RegisterQueryVariables } from 'types/graphql/RegisterQuery';

export const ShareTypes = () => {
  const linguiCtx = useLingui();
  const { previousStep, visitNextStep, mutation } = useWizardContext();
  const [isShareTypeDrawerShown, setShareTypeDrawerShown] = useState(false);
  const [shareTypeVersion, setShareTypeVersion] = useState<ShareTypeVersionWithShareType>();
  const [mode, setMode] = useState<ShareTypeFormMode>(ShareTypeFormMode.Create);
  const labels = getLabels('shareTypes', mutation.register.legalEntity.isFoundation);

  const { data: { register } = {}, refetch } = useQuery<RegisterQuery, RegisterQueryVariables>(
    REGISTER_QUERY,
    {
      variables: {
        registerId: mutation.register.id,
        mutationId: mutation.id,
      },
    },
  );

  const columns = useMemo(() => {
    const columns: TableColumns<ShareType> = [
      {
        id: 'name',
        header: t`Naam`,
        sortingFn: (rowA, rowB) => shareTypeSortingFn(rowA.original, rowB.original),
        accessorFn: (value) => formatShareTypeName(value, false),
      },
      {
        id: 'status',
        header: t`Status`,
        accessorFn: (shareType) => shareType.createdInMutation?.id,
        size: 48,
        cell: ({ row, getValue }) => {
          const firstMutationId = last(row.original.versions)?.createdInMutation?.id;
          const createdInMutationId = getValue<string | null>();
          let label = t`Bestaand`;
          let type: StatusColors = 'default';
          if (mutation.id === firstMutationId) {
            label = t`Aangemaakt`;
            type = 'success';
          } else if (mutation.id === createdInMutationId) {
            label = t`Aangepast`;
            type = 'warning';
          }
          return <Tag status={type}>{label}</Tag>;
        },
      },
    ];
    columns.push({
      id: 'actions',
      header: '',
      size: 64,
      enableSorting: false,
      cell: ({ row }) => {
        const shareType = row.original;
        const { versionId, __typename, ...rest } = shareType;
        const shareTypeVersion: ShareTypeVersionWithShareType = {
          ...rest,
          id: versionId,
          shareType,
        };
        const shouldCorrectShareType = !!shareType.versions.find(
          (version) => !version.createdInMutation || version.createdInMutation.id === mutation.id,
        );
        const options: ActionOption[] = [
          {
            label: t`Wijzigen`,
            onClick: () => {
              setShareTypeVersion(shareTypeVersion);
              setMode(
                shouldCorrectShareType ? ShareTypeFormMode.Correct : ShareTypeFormMode.Update,
              );
              setShareTypeDrawerShown(true);
            },
          },
        ];
        return <ActionMenu label={t`Acties`}>{mapOptionsToActionMenuItems(options)}</ActionMenu>;
      },
    });
    return columns;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [mutation.id, linguiCtx]);

  if (!register) return null;

  return (
    <>
      <WizardHeader previousStep={previousStep} title={labels.header}>
        {labels.introduction}
      </WizardHeader>

      <Card>
        <TableProvider
          columns={columns}
          data={register.shareTypes}
          initialState={{ sorting: [{ id: 'name', desc: false }] }}
          {...tablePagination()}
        >
          <TableCommandBar
            buttons={[
              <Button
                key="button-1"
                iconLeft={<PlusIcon />}
                onClick={() => {
                  setShareTypeVersion(undefined);
                  setMode(ShareTypeFormMode.Create);
                  setShareTypeDrawerShown(true);
                }}
              >
                <Trans>Toevoegen</Trans>
              </Button>,
            ]}
            searchPlaceholder={t`Zoeken...`}
          />
          <Table
            emptyStateProps={{
              title: <Trans>Nog geen soorten</Trans>,
              description: <Trans>Maak een soort aan om door te gaan naar de volgende stap</Trans>,
            }}
            width="100%"
          />
        </TableProvider>
      </Card>

      <ShareTypeSidebarForm
        isSidebarShown={isShareTypeDrawerShown}
        mode={mode}
        mutationId={mutation.id}
        register={register as Register}
        shareTypeVersion={shareTypeVersion}
        onSidebarClose={() => setShareTypeDrawerShown(false)}
        onUpdate={() => refetch()}
      />

      <WizardFooter label={labels.footer}>
        <Button
          isDisabled={register.shareTypes.length < 1}
          variant="primary"
          onClick={() => visitNextStep()}
        >
          <Trans>Volgende</Trans>
        </Button>
      </WizardFooter>
    </>
  );
};
