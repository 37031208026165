import { MenuDivider, MenuItem } from '@elseu/sdu-titan';

export interface ActionOption {
  label?: string;
  onClick?: () => any;
  href?: string;
  isDisabled?: boolean;
  isDivider?: boolean;
  variant?: 'primary' | 'danger';
}
export const mapOptionsToActionMenuItems = (options: ActionOption[]) =>
  options.map((option, index) => {
    // eslint-disable-next-line react/no-array-index-key
    if (option.isDivider) return <MenuDivider key={index} />;
    return (
      <MenuItem
        key={option.label}
        item={{
          value: option.label,
          label: option.label || '',
        }}
        {...option}
      />
    );
  });
