import { Box, Divider, Flex, Link, PlusIcon, Text } from '@elseu/sdu-titan';
import { t, Trans } from '@lingui/macro';
import { Column, Columns } from 'components/Columns';
import { PartySelect } from 'forms/mutation/PartySelect';
import { ShareSeriesInputRow } from 'forms/mutation/ShareSeriesInputRow';
import { defaultSeriesData } from 'mutations/seriesDataCards';
import React, { useCallback } from 'react';
import { useFieldArray, useFormContext } from 'react-hook-form';
import type { IssuancePartyCardProps } from 'wizardsteps/Issuance/IssuancePartyCard';

export const IssuancePartyCardContent = ({
  labels,
  nestedField,
  mutation,
  onPartyCreate,
  onShareTypeCreate,
}: Omit<IssuancePartyCardProps, 'onDelete' | 'onChangeExpanded' | 'isExpanded'>) => {
  const { register } = mutation;
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: `${nestedField}seriesData`,
    keyName: 'key',
  });

  const addRow = useCallback(() => {
    append(defaultSeriesData);
  }, [append]);

  return (
    <>
      <Columns count={2}>
        <Column>
          <PartySelect
            required
            compareDeceaseDate={mutation.effectiveDate}
            createLabel={labels.partyRefCreate}
            label={t`Verkrijger`}
            nestedField={`${nestedField}to.`}
            parties={register.shareholders}
            placeholder={t`Verkrijger`}
            register={register}
            onPartyCreate={onPartyCreate}
          />
        </Column>
      </Columns>
      <Divider spaceAfter={6} />

      <Box spaceAfter={6}>
        <Flex justifyContent="space-between">
          <Text color="grey80" type="labelBold">
            {labels.sharesHeader}
          </Text>
          <Link prefixAdornment={<PlusIcon />} onClick={addRow}>
            <Trans>Toevoegen</Trans>
          </Link>
        </Flex>
      </Box>

      {fields.map(({ key }, mutationIndex) => (
        <ShareSeriesInputRow
          key={key}
          isShareTypeCreateAllowed
          required
          hasPaid={!register.legalEntity.isFoundation}
          isLabelHidden={mutationIndex !== 0}
          labels={labels}
          mutation={mutation}
          nestedField={`${nestedField}seriesData[${mutationIndex}].`}
          onRemove={() => remove(mutationIndex)}
          onShareTypeCreate={onShareTypeCreate}
        />
      ))}
    </>
  );
};
